import React from 'react'
import styles from "./infoblock.module.css"
import Link from 'gatsby-link';
import Img from 'gatsby-image'
 /* 
 Input:
 image
 title
 description
 link
 linkText
 href

 */

const InfoBlock = props => (
    
    <div className='col-4'  >
        <div className={styles.block}>
            <div className={styles.image} >
                <Img title=""
                alt="bild"
                fluid={props.image.childImageSharp.fluid}/>
            </div>
            <div className='container'>
            <h4>{props.title}</h4>
            <p>
            {props.description}
            </p>
            <div className={styles.link}>
            <Link to={props.link}> {props.linkText} </Link>
            </div>
            <div className={styles.link}>
                <a href={props.href}> {props.href}</a>
            </div>
            </div>
        </div> 
    </div>
)


export default InfoBlock;