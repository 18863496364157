/* 
Stand:
Funktion: Erste Seite 
Kopfbild + Inhaltsüberschrift + Info-Blöcker 
Ruft die entsprechenden Seiten über Mehr auf
 */

import React from 'react'
import {useIntl} from "gatsby-plugin-intl"
import Layout from '../components/layout'
import SEO from '../components/seo'
import Header from '../components/header'
import InfoBlock from '../components/infoblock'
import InfoBlockVeranstaltung from '../components/infoblockVeranstaltungen'
import { graphql } from 'gatsby'
import veranstaltungStyles from "../components/infoblock.module.css"


const IndexPage = (props) => {
  const intl = useIntl()
  return(
  <Layout>
    <SEO title="Home" keywords={[`Ficotex`, `3D Druck`, `Deutschland`]} />
  {/*  soll anders aussehen:  z.B. :
  
  */}
    <Header title={intl.formatMessage({ id: "index.UeberSchrift1" })} text={intl.formatMessage({ id: "index.UeberSchrift2" })} />

{/* <div>
  <Img fluid={props.data.imageOne.childImageSharp.fluid}></Img>
 
</div> */}

{/* 
Erster Block:
Entweder Willkommen
oder: Veranstaltung

 */
}
    <div className='row'>

      {/*  1. Vorlage für Veranstaltungen 
      Komponent: Bsild, Title, Kurztext, link und linkText übertragen 
      nur aktuelle Veranstaltungen
      Sicherung unter : 60-7-1-hp-ist-ficotex
      
      */}

      
        {/* <InfoBlockVeranstaltung
        
          link='/Veranstaltungen/Va-191120/'
          linkText={intl.formatMessage({ id: "index.mehr" })}
        >
          
          <h2> {intl.formatMessage({ id: "va.Ueberschrift-1" })} </h2>

          <div className={veranstaltungStyles.veranstaltung} >
          <h4> {intl.formatMessage({ id: "index.VaUe1-201119" })} </h4>
          <h5> {intl.formatMessage({id: "index.VaUeDatum-201119"})}  </h5>
          <h5> {intl.formatMessage({id: "index.VaUeOrt-201119"})}  </h5>

          <p> {intl.formatMessage({id: "index.VaUe2-201119"})}  </p>
          <p> {intl.formatMessage({id: "index.VaUe3-201119"})}  </p>
          <p> {intl.formatMessage({id: "index.VaCorona"})}  </p>
         
    
          </div>
      

          {// die nächste Veranstaltung wird über <div> </div> eingefügt 
          }

          <div className={veranstaltungStyles.veranstaltung} >
          <h5> {intl.formatMessage({ id: "index.VaUe2-201119" })} </h5>
          <p> {intl.formatMessage({id: "index.VaUeDatum-201119"})}  </p>
          <p> {intl.formatMessage({id: "index.VaUeOrt-201119"})}  </p>
          </div>

          <div className={veranstaltungStyles.veranstaltung} >
          <h5> {intl.formatMessage({ id: "index.VaUe3-201119" })} </h5>
          <p> {intl.formatMessage({id: "index.VaUeDatum-201119"})}  </p>
          <p> {intl.formatMessage({id: "index.VaUeOrt-201119"})}  </p>
          </div>
          
      </InfoBlockVeranstaltung> */}
    

      
 
      {// title='Willkommen / als Alternative Zur Veranstaltung' 
      }
      <InfoBlock
        image={props.data.imageWillkommen}
        title={intl.formatMessage({ id: "index.Willkommen" })}
        description={intl.formatMessage({ id: "index.WillkommenText" })}
        
      />

  
   
  

      <InfoBlock
        image={props.data.imageTwo}

        /* title='3D Druck' */

        title={intl.formatMessage({ id: "index.3DDruck" })}
        description={intl.formatMessage({ id: "index.3DDruckText" })}
        link='/Drucker/printer/'
        linkText={intl.formatMessage({ id: "index.mehr" })}
      />


      <InfoBlock
        image={props.data.imageMaterialien}

         /* title='Materialien' */

        title={intl.formatMessage({ id: "index.Material" })}
        description={intl.formatMessage({ id: "index.MaterialText" })}
        link='/Materialien/Materialien/'
        linkText={intl.formatMessage({ id: "index.mehr" })}
      />

      
    </div>  
    
    {/* zweite Reihe   */}

    <div className="row">
  
      {/* */}
      <InfoBlock
        image={props.data.imageFeedstock}

         /* title='keramische Feedstock' */

        title={intl.formatMessage({ id: "index.KeramischeFeedstocks" })}
        description={intl.formatMessage({ id: "index.KeramischeFeedstocksText" })}
      />
       <InfoBlock
        image={props.data.imageMaterialentwicklung}

         /* title='Materialentwicklung' */

        title={intl.formatMessage({ id: "index.MaterialEntwicklung" })}
        description={intl.formatMessage({ id: "index.MaterialEntwicklungText" })}
        
      />
     
      <InfoBlock
        image={props.data.imageProjekte}

         /* title='Projekte' */

        title={intl.formatMessage({ id: "index.Projekte" })}
        description={intl.formatMessage({ id: "index.ProjekteText" })}
      
       
        
      />
      
    </div>
    

      
  
  </Layout>
)
}

export default IndexPage


export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "Veranstaltungen-201031.png" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "Triprint-DSCN8037-mod.jpg" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "druck_schraube_klein.png" }) {
      ...fluidImage
    }
    imageFour: file(relativePath: { eq: "druck_schraube_klein.png" }) {
      ...fluidImage
    }
    imageMaterialien: file(relativePath: { eq: "NF-Pellets-Pruefstab-mk-2018-mod.jpg" }) {
      ...fluidImage
    }
    imageMaterialentwicklung: file(relativePath: { eq: "CAD-Zeichnungen-RK-1.jpg" }) {
      ...fluidImage
    }
    imageProjekte: file(relativePath: { eq: "Druckobjekte-4-zuschnitt.jpg" }) {
      ...fluidImage
    }
    imageFeedstock: file(relativePath: { eq: "Granulat-DSC_0278-mod.jpg" }) {
      ...fluidImage
    }
    imageWillkommen: file(relativePath: { eq: "181205_k3005-mod1.jpg" }) {
      ...fluidImage
    }




  }
`


